export const waitForAnalytics = () =>
	new Promise((resolve) => {
		const clear = setTimeout(() => {
			resolve(false);

			const honeybadger = require('@honeybadger-io/js');
			honeybadger.notify('failed to load analytics');
		}, 3000);

		window.analytics.ready(() => {
			clearTimeout(clear);
			resolve(true);
		});
	});
